import Vue from "vue"
import Router from "vue-router"

const Home = () => import("@/pages/Home.vue")
const SignedChecks = () => import("@/pages/SignedChecks.vue")
const Signatures = () => import("@/pages/Signatures.vue")

Vue.use(Router)

const router = new Router({
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", component: Home, name: "home" },
    { path: "/signed-checks", component: SignedChecks, name: "signed-checks" },
    { path: "/signatures", component: Signatures, name: "signatures" },
  ],
})

export default router
