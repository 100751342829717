<template>
  <div id="app" class="h-100">
    <Header></Header>
    <Navigation></Navigation>
    <AuthGuard class="h-page">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </AuthGuard>
  </div>
</template>

<script>
import "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"

import Header from "@/components/Header.vue"
import Navigation from "@/components/Navigation.vue"

export default {
  components: {
    AuthGuard,
    Header,
    Navigation,
  },
  beforeMount() {
    this.$auth.login()
  },
}
</script>

<style lang="postcss">
@import "./css/aggrid.css";
@import "./css/print.css";
@import "./css/general.css";

html,
body {
  height: 100%;
}
</style>
