import Vue from "vue"
import Vuex from "vuex"

import checks from "./modules/checks"
import comments from "./modules/comments"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    checks,
    comments,
  },
})

export default store
