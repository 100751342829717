/**
 * Vue.js Filters
 *
 * To add a new filter, apply it to the global `Vue` object in the `install  function, then it will
 * be added to the application on load.
 */

import { instructionsOptions } from "./constants"

/**
 * Capitalize a string
 * @param {String} value a string to capitalize
 */
let capitalizeFilter = function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

let titleFilter = function (value) {
  if (!value) return ""
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

let instructionsFilter = function (value) {
  if (!value) return ""
  let { text } = instructionsOptions.find((option) => value === option.value)
  return text
}

// Export as a plugin
const install = (Vue) => {
  Vue.filter("capitalize", capitalizeFilter)
  Vue.filter("title", titleFilter)
  Vue.filter("instructions", instructionsFilter)
}

export default {
  install,
}
