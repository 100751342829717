<template>
  <p>{{ params.value | title }}</p>
</template>

<script>
/**
 * A cell renderer for ag-grid to render the `check.check_identifier` field
 */
export default {
  name: "CheckIdCellRenderer",
}
</script>
