<template>
  <p>{{ humanize }}</p>
</template>

<script>
import { format, parseISO } from "date-fns"

/**
 * A cell renderer for ag-grid to render dates via `date-fns`
 */
export default {
  name: "DateCellRenderer",
  computed: {
    humanize() {
      if (this.params.value !== null) {
        return format(parseISO(this.params.value), "M-d-yyyy, h:mm a")
      }
      return ""
    },
  },
}
</script>
