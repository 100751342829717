<template>
  <p>{{ humanize }}</p>
</template>

<script>
/**
 * A cell renderer for ag-grid to render boolean values in a human readable manner
 */
export default {
  name: "BooleanCellRenderer",
  computed: {
    humanize() {
      return this.params.value === true ? "Yes" : "No"
    },
  },
}
</script>
