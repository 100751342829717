import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
import axios from "axios"
import Doggo from "@uarizona-fnsv/vue-doggo"
import Maska from "maska"
import { sync } from "vuex-router-sync"
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { LicenseManager } from "ag-grid-enterprise"
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"
import "animate.css/animate.css"

import { toastEventBus } from "@/utils/toastEventBus"
import App from "./App.vue"
import router from "./router"
import api from "./utils/api"
import store from "./store"
import filters from "./utils/filters"

import {
  DateCellRenderer,
  CheckIdCellRenderer,
  BooleanCellRenderer,
  InstructionsCellRenderer,
  NoRowsOverlay,
} from "@/components/grid-framework-components"

LicenseManager.setLicenseKey(
  "CompanyName=University of Arizona Financial Services Office,LicensedGroup=Financial Services-IT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-015281,ExpiryDate=12_August_2022_[v2]_MTY2MDI1ODgwMDAwMA==116e7222abf2813c45918e7c8b238dd9",
)

// Sentry configuration
function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname.includes("-dev")) return "dev"
  if (hostname.includes("cloudfront.net")) return "review"
  return "prod"
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: getEnvironment(),
  })
}

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})
Vue.use(Maska)
Vue.use(filters)

sync(store, router)

const addToast = (notification) => {
  toastEventBus.$emit("add", notification)
}

// Add global methods
Vue.prototype.$toast = addToast
Vue.prototype.$http = axios
Vue.prototype.$api = api // For internal API, comes with CSRF token.

// Globally register cell renderer components
Vue.component("DateCellRenderer", DateCellRenderer)
Vue.component("CheckIdCellRenderer", CheckIdCellRenderer)
Vue.component("BooleanCellRenderer", BooleanCellRenderer)
Vue.component("InstructionsCellRenderer", InstructionsCellRenderer)
Vue.component("NoRowsOverlay", NoRowsOverlay)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
